import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import Util, { big } from '../../../../../../util/Util';
import Logo from '../../../../../../images/doc-logo.png'
import { PDFTable } from '../../../../../../pdfs/invoice-document/pdf/Invoice';
import Api from '../../../../../../session/Api';
import { stringifyBlueprintInputFields } from '../../../../../activities/components/blueprint-input-fields-read';

import { ToWords } from 'to-words';
import { SHIPMENT_DIR_TYPE } from '../../..';
import { getBLNo } from '../../../../../../domain/shipment';
import UIUtil from '../../../../../../util/UIUtil';
import picardlogo from '../../../../../../images/other/picard/picardlogo.png';
import { isPicard } from '../../../../../../app/app-util';

const numToWordFuncs = {
    aed: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase().replace("dollar", "dirham").replace("cent", "fil"),
    gbp: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase().replace("dollar", "pound").replace("cent", "pence"),
    usd: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase(),
    eur: num => new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        },
    }).convert(num).toLowerCase().replace("dollar", "euro").replace("cent", "cent"),
}

const GRAY = "#F2F2F2";
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    contentField2: {
        fontSize: 8
    }
});


function entityInfo({ name, address, poBox, nationalId, tel, fax, email, website, note }) {
    let text = name;
    if (Util.isStringExists(address) || Util.isStringExists(poBox)) {
        if (Util.isStringExists(address) && Util.isStringExists(poBox)) {
            text += `\nAdd: ${address}, PO BOX: ${poBox}`;
        } else if (Util.isStringExists(address)) {
            text += `\nAdd: ${address}`;
        } else {
            text += `\nPO BOX: ${poBox}`;
        }
    }
    if (Util.isStringExists(nationalId)) {
        text += "\nNational ID: " + nationalId;
    }
    if (Util.isStringExists(tel) || Util.isStringExists(fax)) {
        if (Util.isStringExists(tel) && Util.isStringExists(fax)) {
            text += `\nTel: ${tel}, Fax: ${fax}`;
        } else if (Util.isStringExists(tel)) {
            text += `\nTell: ${tel}`;
        } else {
            text += `\nFax: ${fax}`;
        }
    }
    if (Util.isStringExists(email)) {
        text += "\nEmail: " + email;
    }
    if (Util.isStringExists(website)) {
        text += "\nWebsite: " + website;
    }
    if (Util.isStringExists(note)) {
        text += "\n" + note;
    }
    return text;
}

function consignorMessage(customer, note) {
    if (customer) {
        return entityInfo({
            name: customer.fullName,
            address: customer.address,
            poBox: customer.poBox,
            nationalId: customer.nationalId,
            tel: customer.phoneNumber,
            fax: customer.fax,
            email: customer.email,
            website: customer.website,
            note,
        })
    } else {
        return "";
    }
}

function consigneeMessage(item, note) {
    if (item) {
        return entityInfo({
            name: item.name,
            address: item.address,
            poBox: item.poBox,
            nationalId: item.nationalId,
            tel: item.phoneNumber,
            fax: item.faxNumber,
            email: item.email,
            website: item.website,
            note,
        })
    } else {
        return "";
    }
}

function notifyParent(item) {
    if (!item.notifyPartySameAsConsignee) {
        if (Util.isStringExists(item.notifyPartyName)) {
            return entityInfo({
                name: item.notifyPartyName,
                address: item.notifyPartyAddress,
                poBox: item.notifyPartyPoBox,
                nationalId: item.notifyPartyNationalId,
                tel: item.notifyPartyTel,
                fax: item.notifyPartyFax,
                email: item.notifyPartyEmail,
                website: item.notifyPartyWebsite,
                note: item.notifyPartyNote,
            })

            // let text = item.notifyPartyName;
            // text += "\n" + (item.notifyPartyAddress ?? "");
            // text += "\nP.O. Box: " + (item.notifyPartyPoBox ?? "");
            // text += "\nTel: " + (item.notifyPartyTel ?? "") + ", " + "Fax: " + (item.notifyPartyFax ?? "")
            // return text;
        } else {
            return ""
        }
    } else {
        return "SAME AS CONSIGNEE";
    }
}

const Section = ({ titleFlex, contentFlex, title, content, borderBottom, textAlign = "left", contentSize = 9, children }) => (
    <View style={{ borderBottom, flexDirection: 'row' }}>
        <View style={{ flex: titleFlex, paddingLeft: 2, paddingBottom: 2, paddingTop: 2, width: '100%', backgroundColor: '#F2F2F2', alignItems: 'flex-start', justifyContent: 'center', }}>
            <Text style={{ fontSize: 8, margin: 0, lineHeight: 0, fontFamily: "Helvetica" }}>{title}</Text>
        </View>
        <Text style={{ flex: contentFlex, textAlign, fontSize: contentSize, fontFamily: 'Helvetica-Bold', paddingTop: 5, paddingBottom: 2, paddingLeft: 2, paddingRight: 5 }}>{Util.isStringExists(content) ? content : ' '}</Text>
    </View>
)


const VertSection = ({ title, content, borderBottom, children }) => (
    <View style={{ borderBottom, flexDirection: 'column' }}>
        <View style={{ paddingLeft: 2, paddingBottom: 2, paddingTop: 2, width: '100%', backgroundColor: '#F2F2F2', alignItems: 'flex-start', justifyContent: 'center', }}>
            <Text style={{ fontSize: 8, margin: 0, lineHeight: 0, fontFamily: "Helvetica" }}>{title}</Text>
        </View>
        <Text style={{ fontSize: 9, fontFamily: 'Helvetica-Bold', paddingTop: 5, paddingBottom: 2, paddingLeft: 2, paddingRight: 5 }}>{content}</Text>
    </View>
)

const FullVertSection = ({ title, content, borderBottom, children }) => (
    <View style={{ borderBottom, flexDirection: 'column', width: '100%' }}>
        <View style={{ paddingLeft: 2, paddingBottom: 2, paddingTop: 2, width: '100%', backgroundColor: '#F2F2F2', alignItems: 'flex-start', justifyContent: 'center', }}>
            <Text style={{ fontSize: 8, margin: 0, lineHeight: 0, fontFamily: "Helvetica" }}>{title}</Text>
        </View>
        <Text style={{ fontSize: 9, fontFamily: 'Helvetica-Bold', paddingTop: 5, paddingBottom: 2, paddingLeft: 2, paddingRight: 5 }}>{content}</Text>
    </View>
)

function PDF({ shipment, payload: master, title }) {
    const invoice = shipment.invoice;

    const vesselName = (() => {
        return master?.transportationLegs.map(leg => leg.vesselName).join(" -> ");
    })();
    const voyageNo = (() => {
        return master?.transportationLegs.map(leg => leg.voyageNo).join(" -> ");
    })();

    const blNo = (() => {
        return getBLNo(shipment)
    })();

    const totalShipmentQty = shipment.packages
        .flatMap($ => $.items)
        .map($ => $.quantity)
        .filter(val => Util.isNumberExist(val))
        .map(val => big(val))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()


    const totalShipmentWeight = shipment.packages
        .flatMap($ => $.items)
        .map($ => $.weight)
        .filter(val => Util.isNumberExist(val))
        .map(val => big(val))
        .reduce((t, c) => t.add(c), big(0))
        .round(2).toNumber()

    const pol = (() => {
        const firstLeg = master?.transportationLegs[0];
        return firstLeg?.departurePortName ?? "-"
    })();
    const pod = (() => {
        const lastLeg = master?.transportationLegs[master?.transportationLegs.length - 1];
        return lastLeg?.arrivalPortName ?? "-"
    })();
    const arrivalDate = (() => {
        const lastLeg = master?.transportationLegs[master.transportationLegs.length - 1];
        return Util.getDateOnly(Util.isNumberExist(lastLeg?.actualEndDate) ? lastLeg?.actualEndDate : lastLeg?.scheduledEndDate)
    })();

    const isExport = shipment.dirType === SHIPMENT_DIR_TYPE.EXPORT;

    const descOfGoods = shipment.packages
        .flatMap($ => $.items)
        .map(item => (item.remark ?? ""))
        .filter(Util.isStringExists)
        .join("\n")

    const currency = invoice.currency ?? "AED";
    const numToWord = numToWordFuncs[currency.toLowerCase()]

    return (
        <Document>
            <Page size="A4" orientation='portrait' style={styles.page}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    {isPicard() ? <>
                        <Image src={picardlogo} style={{ width: 150, height: 80, objectFit: 'contain', objectPosition: 'top left' }} />
                    </> : <>
                        <Image src={Logo} style={{ width: 75, height: 75 }} />
                    </>}
                    <View style={{ flex: 1 }} />
                    {isPicard() ? <>
                        <View style={{ marginTop: 0, alignItems: 'flex-end' }}>
                            <Text style={{ fontSize: 10, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'right', color: '#00148C' }}>{"Picard Logistics"}</Text>
                            <Text style={{ fontSize: 8, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'right', color: '#00148C' }}>
                                {"FZS5 BB02, 1209 Street\nJebel Ali Free Zone [Jafza]\nDubai, UAE, P.O. Box: 17728\nInfo@picardlogistics.com\n+971 4 3588698"}
                            </Text>
                        </View>
                    </> : <>
                        <View style={{ marginTop: 7, alignItems: 'flex-end' }}>
                            <Text style={{ fontSize: 12, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'right', color: '#00148C' }}>{"EXCELLS SHIPPING LLC"}</Text>
                            <Text style={{ fontSize: 10, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'right', color: '#00148C' }}>{"P.O.BOX: 63067, DUBAI - U.A.E\nTEL: +971 4 2209620, FAX: +971 4 2209610\nEmail: info@excellsshipping.com\nWebsite: www.excellsshipping.com"}</Text>
                        </View>
                    </>}
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: 15 }}>
                    <View style={{ border: '1px solid #5c5c5c', flex: 1 }}>
                        <Section titleFlex={2} contentFlex={3} title="Invoice No" content={invoice.invoiceNo} borderBottom="1px solid #5c5c5c" />
                        <Section titleFlex={2} contentFlex={3} title="Invoice Date" content={Util.formatDate(invoice.date, "DD.MM.YYYY")} />
                    </View>
                    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 2 }}>
                        <Text style={{ textAlign: 'center', fontFamily: 'Helvetica-Bold', textDecoration: 'underline', fontSize: 14, }}>{title}</Text>
                    </View>
                    <View style={{ border: '1px solid #5c5c5c', flex: 1 }}>
                        <Section titleFlex={1} contentFlex={2} title="TRN No" content={invoice.systemCompany.trn} borderBottom="1px solid #5c5c5c" />
                        <Section titleFlex={1} contentFlex={2} title="Job No" content={shipment.shipmentNo} />
                    </View>
                </View>
                <View style={{ border: '1px solid #5c5c5c', marginTop: 7 }}>
                    <View style={{ flexDirection: 'row', borderBottom: '1px solid #5c5c5c' }}>
                        <View style={{ flex: 1, borderRight: '1px solid #5c5c5c' }}>
                            <VertSection title={"To,"} content={invoice.customerName + '\n\n' + invoice.customerFullAddress} />
                            <VertSection title={"TRN"} content={invoice.customerTrn} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Section titleFlex={2} contentFlex={6} borderBottom="1px solid #5c5c5c" title="Shipper" content={shipment.customer?.fullName ?? ''} />
                            <Section titleFlex={2} contentFlex={6} borderBottom="1px solid #5c5c5c" title="Consignee" content={shipment.consignee?.name ?? ''} />
                            {isExport && <Section titleFlex={2} contentFlex={6} title="Notify" content={shipment.notifyPartySameAsConsignee ? "SAME AS CONSIGNEE" : (shipment.notifyPartyName ?? '')} />}
                        </View>
                    </View>
                    {isExport && <View style={{ flexDirection: 'row', borderBottom: '1px solid #5c5c5c' }}>
                        <View style={{ flex: 2, borderRight: '1px solid #5c5c5c' }}>
                            <Section titleFlex={1} contentFlex={4} title="Origin" content={pol} borderBottom="1px solid #5c5c5c" />
                            <Section titleFlex={1} contentFlex={4} title="No(Pcs)" content={totalShipmentQty} borderBottom="1px solid #5c5c5c" />
                            <Section titleFlex={1} contentFlex={4} title="HBL NO" content={blNo} />
                        </View>
                        <View style={{ flex: 2, borderRight: '1px solid #5c5c5c' }}>
                            <Section titleFlex={1} contentFlex={4} title="ETA/ETS" content={arrivalDate} borderBottom="1px solid #5c5c5c" />
                            <Section titleFlex={1} contentFlex={4} title="Wt(Kgs)" content={totalShipmentWeight} borderBottom="1px solid #5c5c5c" />
                            <Section titleFlex={1} contentFlex={4} title="B.E. No" content={shipment.billOfEntryNo} />
                        </View>
                        <View style={{ flex: 3 }}>
                            <Section titleFlex={1} contentFlex={4} title="Destination" content={pod} borderBottom="1px solid #5c5c5c" />
                            <Section titleFlex={1} contentFlex={4} title="Vessel" content={vesselName} borderBottom="1px solid #5c5c5c" />
                            <Section titleFlex={1} contentFlex={4} title="Voy. No" content={voyageNo} />
                        </View>
                    </View>}
                    <View style={{ flexDirection: 'row', borderBottom: '1px solid #5c5c5c', width: '100%' }}>
                        <FullVertSection title="Description of Goods" content={descOfGoods} />
                    </View>
                    <View style={{ flexDirection: 'row', }}>
                        <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, borderRight: '1px solid #5c5c5c', flex: 1 }}>SNO</Text>
                        <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, borderRight: '1px solid #5c5c5c', flex: 5 }}>DESCRIPTION</Text>
                        <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, borderRight: '1px solid #5c5c5c', flex: 1 }}>QTY</Text>
                        <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, borderRight: '1px solid #5c5c5c', flex: 2 }}>RATE</Text>
                        <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, borderRight: '1px solid #5c5c5c', flex: 3 }}>AMOUNT</Text>
                        <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, borderRight: '1px solid #5c5c5c', flex: 1 }}>VAT</Text>
                        <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, flex: 2 }}>TAX</Text>
                    </View>
                </View>

                <View style={{ flex: 1 }}>
                    {invoice.items.map((item, index) => (
                        <View style={{ flexDirection: 'row', }}>
                            <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, textAlign: 'left', flex: 1 }}>{index + 1}</Text>
                            <Text style={{ fontSize: 9, paddingLeft: 4, paddingTop: 4, textAlign: 'left', flex: 5 }}>{item.description + (Util.isStringExists(item.memo) ? ('\n' + item.memo) : '')}</Text>
                            <Text style={{ fontSize: 9, paddingRight: 4, paddingTop: 4, textAlign: 'right', flex: 1 }}>{item.qty}</Text>
                            <Text style={{ fontSize: 9, paddingRight: 4, paddingTop: 4, textAlign: 'right', flex: 2 }}>{Util.formatMoneyWithNeg(item.unitPrice)}</Text>
                            <Text style={{ fontSize: 9, paddingRight: 4, paddingTop: 4, textAlign: 'right', flex: 3 }}>{Util.formatMoneyWithNeg(item.total)}</Text>
                            <Text style={{ fontSize: 9, paddingRight: 4, paddingTop: 4, textAlign: 'right', flex: 1 }}>{item.vatRate === null ? '' : (item.vatRate + '%')}</Text>
                            <Text style={{ fontSize: 9, paddingRight: 4, paddingTop: 4, textAlign: 'right', flex: 2 }}>{Util.formatMoneyWithNeg(item.vatAmount)}</Text>
                        </View>
                    ))}
                </View>

                <View style={{ border: '1px solid #5c5c5c', flexDirection: 'row' }}>
                    <View style={{ flex: 3, padding: 2, borderRight: '1px solid #5c5c5c' }}>
                        <VertSection title={"Total"} content={numToWord(invoice.totals.grandTotal)} />
                    </View>
                    <View style={{ flex: 2 }}>
                        <Section textAlign='right' contentSize={12} titleFlex={1} contentFlex={2} title="Amount Excl. VAT" content={currency + " " + Util.formatMoney(invoice.totals.subTotal)} borderBottom="1px solid #5c5c5c" />
                        <Section textAlign='right' contentSize={12} titleFlex={1} contentFlex={2} title="VAT Amount" content={currency + " " + Util.formatMoney(invoice.totals.vatTotal)} borderBottom="1px solid #5c5c5c" />
                        <Section textAlign='right' contentSize={12} titleFlex={1} contentFlex={2} title="Net Total" content={currency + " " + Util.formatMoney(invoice.totals.grandTotal)} />
                    </View>
                </View>
                {isPicard() ? (
                    <Text style={{ fontSize: 8 }}>** Thanking you for choosing Picard Logistics as your logistics service provider.</Text>
                ) : (
                    <Text style={{ fontSize: 8 }}>** Thanking you for choosing EXCELLS SHIPPING LLC as your logistics service provider.</Text>
                )}
                <Text style={{ fontSize: 8 }}>** Any discrepancy please notify within 7 days of date of Invoice, otherwise it will be considered as final.</Text>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ border: '1px solid #5c5c5c', flex: 1, padding: 2 }}>
                        <VertSection title={"Bank Details"} />
                        <Text style={styles.contentField2}>
                            Company Name: {invoice.systemCompany.name}
                        </Text>
                        <Text style={styles.contentField2}>
                            Bank Account No: {invoice.systemCompany.bankAccountNo}
                        </Text>
                        <Text style={styles.contentField2}>
                            IBAN: {invoice.systemCompany.iban}
                        </Text>
                        <Text style={styles.contentField2}>
                            Bank: {invoice.systemCompany.bankName}
                        </Text>
                        <Text style={styles.contentField2}>
                            Branch: {invoice.systemCompany.branch}
                        </Text>
                        <Text style={styles.contentField2}>
                            Swift Code: {invoice.systemCompany.swiftCode}
                        </Text>
                    </View>
                    <View style={{ flex: 1, padding: 2 }}>

                    </View>
                </View>
            </Page>
        </Document>
    )
}

export function createInvoice(shipment, invoiceId) {
    return {
        PDF,
        canGenerate: true,
        getPayload: Util.isNumberExist(shipment.shipmentMasterId) ? (shipment, listener) => {
            Api.getShipmentMaster(shipment.shipmentMasterId, response => {
                if (response.status === true) {
                    listener(true, response.payload.item)
                } else {
                    listener(false, response.message);
                }
            })
        } : undefined,
        overrideShipment: shipment => {
            let invoice = shipment.invoice;
            if (invoiceId && shipment.otherInvoices.some($ => $.fromEntityId == invoiceId)) {
                invoice = shipment.otherInvoices.find($ => $.fromEntityId == invoiceId);
            }

            return { ...shipment, invoice }
        }
    }
}

export function createCurrencyInvoice(shipment) {
    return {
        PDF,
        canGenerate: true,
        getPayload: createInvoice(shipment).getPayload,
        overrideShipment: async shipment => {
            const currencies = await Api.asyncGetCurrencies()
            const currency = await UIUtil.listPrompt("Select Currency", currencies);
            if (!currency) {
                return;
            }

            const invoice = await Api.asyncGetShipmentConvertedInvoice(shipment.id, currency.id);

            return {
                ...shipment, invoice
            }
        }
    }
}